.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  max-width: 1600px;
  margin: auto;
}

@media only screen and (min-width: 1025px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
    max-width: 1600px;
    margin: auto;
  }
}

.navItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}

.navItems svg {
  margin-right: 0.5rem;
}

.defaultUser__icon {
  padding: 0.375rem;
  border-radius: 100%;
  border: 2px solid #fff;
  font-size: 2.5rem;
}

.logout {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0;
}

@media only screen and (min-width: 768px) {
  .logout {
    font-size: 1rem;
  }
}

.logout svg {
  font-size: 1.5rem;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
  border: 2px solid white;
}

.button {
  margin-left: 1rem;
}

h3>button {
  background: none;
  border: none;
  color: white;
}