:root {
  --reach-dialog: 1;
}

@import './reset.css';
@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Bold.eot');
  src: url('./fonts/Whitney-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Whitney-Bold.woff2') format('woff2'),
    url('./fonts/Whitney-Bold.woff') format('woff'),
    url('./fonts/Whitney-Bold.ttf') format('truetype'),
    url('./fonts/Whitney-Bold.svg#Whitney-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Book.eot');
  src: url('./fonts/Whitney-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Whitney-Book.woff2') format('woff2'),
    url('./fonts/Whitney-Book.woff') format('woff'),
    url('./fonts/Whitney-Book.ttf') format('truetype'),
    url('./fonts/Whitney-Book.svg#Whitney-Book') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Medium.eot');
  src: url('./fonts/Whitney-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Whitney-Medium.woff2') format('woff2'),
    url('./fonts/Whitney-Medium.woff') format('woff'),
    url('./fonts/Whitney-Medium.ttf') format('truetype'),
    url('./fonts/Whitney-Medium.svg#Whitney-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

:global(html) {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

:global(body) {
  margin: 0;
  height: 100%;
  overflow-y: auto;
  font-family: 'Whitney', sans-serif;
  font-size: 16px;
  color: #9199b2;
  background-color: #fbfdff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(#root) {
  height: 100%;
}

.siteHeader {
  position: relative;
  background: #747a8e;
  padding: 0.8rem 0;
}

.siteHeader a {
  color: #fff;
  text-decoration: none;
}

[data-reach-tooltip] {
  pointer-events: none;
  position: absolute;
  padding: 0.25em;
  box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.1);
  white-space: nowrap;
  font-size: 85%;
  background: #f0f0f0;
  color: #444;
  border: solid 1px #ccc;
}

textarea {
  resize: none;
  vertical-align: top;
}
