.loader {
  height: 100%;
  position: relative;
}

.loader__layout {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 15;
  display: flex;
  background: rgba(235, 237, 240, 0.85);
  justify-content: center;
  align-items: center;
}

.loader__ellipsis {
  display: inline-block;
  position: fixed;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #747a8e;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader__ellipsis div:nth-child(1) {
  left: 6px;
  animation: ellipsis1 0.6s infinite;
}

.loader__ellipsis div:nth-child(2) {
  left: 6px;
  animation: ellipsis2 0.6s infinite;
}

.loader__ellipsis div:nth-child(3) {
  left: 26px;
  animation: ellipsis2 0.6s infinite;
}

.loader__ellipsis div:nth-child(4) {
  left: 45px;
  animation: ellipsis3 0.6s infinite;
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
