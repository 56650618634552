.container {
  position: fixed;
  right: 100px;
  top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 157);
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
  box-shadow: 4px 4px 5px -3px grey;
}

.container.show {
  display: flex;
  opacity: 0.8;
  pointer-events: all;
}

.message {
  font-size: 1.2rem;
  padding: 1rem;
}

.icon {
  font-size: 1.2rem;
  cursor: pointer;
  padding: 1rem;
}